<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标题" v-model="searchParam.questionnaireName" />
        <v-date-picker
          label="生成时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="生成开始时间"
          endPlaceholder="生成结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="发布"
          type="text"
          @click="publishOrCancel(scope.row, 1)"
          v-if="scope.row.status == 0"
        />
        <v-button
          text="取消"
          type="text"
          @click="publishOrCancel(scope.row, 0)"
          v-else
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  psychologicalEvaluationListUrl,
  questionnairePublishUrl,
  questionnaireDeleteUrl,
} from "./api.js";
import {} from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
export default {
  data() {
    return {
      searchParam: {
        questionnaireName: null,
        startDate: null,
        endDate: null,
      },
      tableUrl: psychologicalEvaluationListUrl,
      headers: [
        {
          prop: "id",
          label: "编号",
        },
        {
          prop: "questionnaireName",
          label: "评估标题",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              cb: () => this.toTitle(row),
            }),
        },
        {
          prop: "createTime",
          label: "生成时间",
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.startDate && !this.searchParam.endDate
          ? []
          : [this.searchParam.startDate, this.searchParam.endDate];
      },
      set(val) {
        [this.searchParam.startDate, this.searchParam.endDate] = val || [
          "",
          "",
        ];
      },
    },
  },
  methods: {
    // 跳转得分列表
    toTitle(item) {
      this.$router.push({
        name: "tebbagersScoreList",
        query: {
          name: item.questionnaireName,
          id: item.id,
        },
      });
    },
    // 新增
    toAdd() {
      this.$router.push({
        name: "tennagersMindForm",
      });
    },
    // 删除
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          id: item.id,
        };
        this.$axios
          .post(`${questionnaireDeleteUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    // 编辑
    toEdit(item) {
      this.$router.push({
        name: "tennagersMindForm",
        query: {
          id: item.id,
        },
      });
    },
    // 发布/取消
    async publishOrCancel(item, status) {
      let params = {
        id: item.id,
        status: status,
      };
      let res = await this.$axios.post(
        questionnairePublishUrl,
        this.$qs.stringify(params)
      );
      if (res.code == 200) {
        this.$message.success("操作成功");
        this.$refs.list.search();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>